/*eslint-disable*/
import React, { useState, useEffect } from "react";
/*eslint-disable*/

import { MarketPlaceLoadStateHandler } from "../../react/MarketPlaceAutomate";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import styled from "styled-components";
import { flow } from "fp-ts/lib/function";
import loadScript from "../../react/loadscript";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import KeyResources from "../../components/common/KeyResources";
import { useMedia } from "use-media";
import {CustomerMobile} from "./demo"


export const blogImg = require("../../assets/img/key-resources/bc_1_4.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_4.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_4.png");

const topImage = require("../../assets/images/automate_usp/marketplace_img.png");
const { RequestForm } = require("../../components/form");

const WrapperContent = styled.div`
  width: 100%;
  float: left;

  @media (max-width: 767px) {
    .bg_marketpalce .cc_feature_apps_header h3 {
      text-align: center;
      font-family: "Roboto-Medium";
      font-size: 26px;
      padding: 0;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  @media (max-width: 520px) {
    background: #f8f8f8;
    margin-bottom: 40px;

    .bg_marketpalce .cc_feature_apps_header h3 {
      text-align: center;
      font-family: "Roboto-Medium";
      font-size: 24px;
      color: #000;
      padding: 0;
      margin-bottom: 10px;
      margin-top: 00px;
    }
  }
`;

export default function MarketPlace(props) {
  return (
    <>
      <TitleAndMetaTags
        title="Pre-built automations and actions  | Workativ Automate"
        description="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations",
        ]}
        ogImage={topImage}
        ogTitle="Pre-built automations and actions  | Workativ Automate"
        ogDescription="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
      />
      <Container>
        <div className="automate_marketplace automate_product automate_integrations new_automate_background new_mobile_home_design ">
          <Layout
            backgroundColor={" automate_marketplace pad_0"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
          >
            <PostHeaderSection />
            <AfterHeaderSection />
            <MarketPlaceLoadStateHandler {...props} />
            {/* <ReachToUsSection /> */}
            {/* <KeyResources cards={resoucesData} /> */}
            <WhyAutomate />
            <CustomerMobile />
            <div className="automate_homepage">
              <RequestForm
                product="automate"
                content="Start on our free plan, and scale up as you grow."
                AutomateCTA
                automateContent
                autoamteButton
                automateName
                isFooterForm={true}
              />
            </div>
          </Layout>
        </div>
      </Container>
    </>
  );
}

function Loader() {
  return (
    <div className="loader_demo" id="loader_demo">
      <div className="loader"></div>
    </div>
  );
}
function AfterHeaderSection() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section
      className={`width-100 float-left display-none-mobile ${
        isSmall ? "mt-4" : "mt-5"
      }`}
    >
      <div className="container">
        <h3 className="font-section-sub-header text-align-left color-light-gray">
          {" "}
          Select your Applications
        </h3>
        <hr></hr>
      </div>
    </section>
  );
}
function WhyAutomate() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section
      className={`why_autoamte_wrapper why_automate_wrappper_integration_update ${
        isSmall ? "mt-0" : null
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-12 why-automate-right-main-flex">
            <h3 className="font-section-sub-header">Why Workativ Automate?</h3>
            <p className="font-section-normal-text-testimonials line-height-18">
              Most workflow products are complex, expensive, and bloated with
              things that you don’t need. Workativ Automate is purpose built
              iPaaS to help you connect and automate your workplace tasks. It’s
              easy to use yet powerful, and affordable to all.
            </p>
            {isSmall ? null : (
              <div className="why-automate-customer-banner">
                <h5 className="font-section-sub-header-slider banner-header-why-automate">
                  Join leading innovators
                </h5>
                <img
                  src={require("../../assets/automate/home/automate_clients_logo_mobile.svg")}
                  alt="join leading innovators"
                />
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-6 col-12">
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_1.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  Simple and Intuitive
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Create app integrations and workflows using simple UI
                  interface. No coding or developer support required.
                </p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_2.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  Powerful Tools
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Format data, add conditional logic, set approvals, or time
                  intervals into your workflows.
                </p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_3.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  100+ Connectors
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Connect 100+ cloud apps. Use app triggers, webhooks to run
                  your workflows.
                </p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_4.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  Secure & Reliable
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18 pb-0">
                  Data encryption at rest and transit, data retention controls
                  and audit logs. ISO 27001 certified.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function PostHeaderSection() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <section className="marketplace_header_container width-100 float-left">
      <div className="marketplace_autoamte_apps automate_marketplace all_apps_automate_marketplace_integration">
        <div className="container">
          <div className="row">
            <div className="col-12 autoamte_apps_header autoamte_apps_header_selcted">
              {isSmall ? (
                <h1 className="font-page-header">
                  Deliver workflow automation in seconds. Just download. No
                  coding
                </h1>
              ) : (
                <h1 className="font-page-header">
                  <span>Deliver workflow automation in seconds. </span>
                  <span>Just download. No coding</span>
                </h1>
              )}
              <p className="font-section-normal-text-testimonials line-height-18">
                Pick from over 400+ pre-built automations on popular apps, and
                automate your employee support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function PreFooterSection() {
  return (
    <div className="container-fluid pl-0 pr-0">
      <img src={require("../../react/images/mp_footer.png")} alt="footer_img" />
    </div>
  );
}

export function ReachToUsSection() {
  const [showModal, setShowModal] = useState(false);

  const [state, setState] = useState({ useCase: "", email: "" });

  function closemodel(callback) {
    document.getElementById("exampleModalCenter").className = "modal fade";
    document.getElementById("exampleModalCenter").style.display = "none";
    if (callback) {
      callback();
    }

    setShowModal(false);
  }

  function enableSubmit() {
    return state.useCase.length > 0 && state.email.length > 0;
  }

  useEffect(() => {
    // console.log("load scropt");
    if (!window._load_script) {
      loadScript("/marketPlaceScript.js")
        .then((script) => {
          // console.log("load scropt success");
          // console.log(script);
          // Grab the script object in case it is ever needed.
          // this.mapScript = script;
          // this.setState({ apiLoaded: true });
        })
        .catch((err) => {
          console.log("load scropt error");
          console.error(err.message);
        });
    }
  }, []);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <WrapperContent>
      <section className="apps_integrations bg_marketpalce">
        <div className="cogni_fav_apps ">
          <div className="container">
            <div className="marketplace_integrations_icons">
              <div className="cc_feature_apps_header">
                {isSmall ? (
                  <h3>
                    Didn’t find the automation you were looking for? No worries,
                    we will create one for you.
                  </h3>
                ) : (
                  <h3>
                    Didn’t find the automation you were looking for? <br></br>No
                    worries, we will create one for you.
                  </h3>
                )}
                <div className="modal_submit_form">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModalCenter1"
                    onClick={() => setShowModal(true)}
                  >
                    Reach out to us
                  </button>

                  <div
                    className="modal fade show"
                    id="exampleModalCenter1"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    style={{ display: showModal ? "flex" : "none" }}
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div
                        className="modal-content modal-content-popup"
                        id="beta-detail-page"
                      >
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => closemodel()}
                          >
                            <span
                              className="btn_close_popup"
                              aria-hidden="true"
                            >
                              ×
                            </span>
                          </button>
                        </div>
                        <div className="modal-body-header">
                          <div className="modal-body">
                            <form
                              id="_form_22_"
                              className="_form _form_22 _inline-form  _dark"
                              noValidate
                            >
                              <input type="hidden" name="u" defaultValue={22} />
                              <input type="hidden" name="f" defaultValue={22} />
                              <input type="hidden" name="s" />
                              <input type="hidden" name="c" defaultValue={0} />
                              <input type="hidden" name="m" defaultValue={0} />
                              <input
                                type="hidden"
                                name="act"
                                defaultValue="sub"
                              />
                              <input type="hidden" name="v" defaultValue={2} />
                              <div className="_form-content">
                                <div className="_form_element _x41893381 _full_width _clear">
                                  <div className="_form-title">
                                    Request for Use Case
                                  </div>
                                </div>
                                <div className="_form_element _x65116826 _full_width _clear">
                                  <div className="_html-code">
                                    <p>
                                      Tell us of your use case requirement in a
                                      few lines.
                                    </p>
                                  </div>
                                </div>
                                <div className="_form_element _field16 _full_width ">
                                  <label className="_form-label">
                                    Automation use case *
                                  </label>
                                  <div className="_field-wrapper">
                                    <textarea
                                      id="useCase"
                                      name="field[16]"
                                      placeholder="Automation use case "
                                      required
                                      defaultValue={""}
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          useCase: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="_form_element _x68473619 _full_width ">
                                  <label className="_form-label">Email*</label>
                                  <div className="_field-wrapper">
                                    <input
                                      id="email"
                                      type="text"
                                      name="email"
                                      placeholder="Type your email"
                                      required
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          email: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="_button-wrapper _full_width">
                                  <button
                                    id="_form_22_submit"
                                    className="_submit"
                                    type="submit"
                                    disabled={!enableSubmit()}
                                    style={{
                                      pointerEvents: enableSubmit()
                                        ? "visible"
                                        : "none",
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                                <div className="_clear-element" />
                              </div>
                              <div
                                className="_form-thank-you"
                                style={{ display: "none" }}
                              />
                            </form>
                          </div>
                          <div className="modal_body_right">
                            <img
                              src={require("../../assets/images/landing/register.png")}
                              alt="register"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WrapperContent>
  );
}

const resoucesData = [
  {
    image: blogImg,
    header:
      "Browse through topics on AI, Automation, Chatbots and Workplace support",
    url: "https://blog.workativ.com/",
    link: "Read Blog",
    alt: "blog",
  },
  {
    image: whitepaperImg,
    header:
      "What’s the real value for business in transforming workplace support?",
    url: "/conversational-ai-platform/chatbot-whitepaper",
    link: "Read Whitepaper",
    alt: "whitepaper",
  },
  {
    image: videoImg,
    header:
      "Watch videos to learn about all the capabilities of Workativ Assistant",
    url: "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    link: "Watch Now",
    alt: "videoImg",
  },
];
