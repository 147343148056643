import "./react.css";

import * as immer from "immer";
import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import { MarketPlaceItemsAutomate } from "./Applications";
import { CardsApplications } from "./CardApplications";
import { DownArrowCircle } from "./Icons";
import { NextStepButton, SelectedItems } from "./SelectedItems";
import { staticResponse } from "./StaticResponse.jsx";
import Mdatas from './marketdata.json'
import {
  allAreSame,
  getAllSubsets,
  uniqueMarketPlaceApps,
  uniqueMarketPlaceAutomations
} from "./util";

const axios = require("axios").default;


function callApi() {
  // return Promise.resolve(staticResponse);
  // const url = process.env.GATSBY_APP_APPS_LIST;
  // const url = "http://localhost:2019/api/assistant/automate-marketplace"
  const url = window.location.origin + "/api/assistant/automate-marketplace"
  // console.log("AUTOMATE :: callAPI", callApi);
  const options = {
    url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8"
    }
  };


  // let x = Promise.resolve(Mdatas)
  // return x
  return axios(options)
    .then(resp => resp.data)
    .then(data => data.output)
    .then(output => {
      // console.log("AUTOMATE apps 02", output)
      const pairs = R.toPairs(output)
      const categories = R.map(([name, appArray]) => {
        const id = appArray[0].categoryId
        const count = appArray.length
        return {
          name,
          id,
          count
        }

      })(pairs)

      const apps = R.map(([, appArray]) => {
        return appArray
      })(pairs)

      // console.log("AUTOMATE apps", apps)

      const allApps = R.reduce((x, y) => {
        // console.log("x,y", x, y)
        return ([...x, ...y])
      }, [], apps)
      // console.log("AUTOMATE checking", allApps)

      return {
        categories,
        apps: allApps
      }

    })
    .catch(error => error);
}

const sortByNameCaseInsensitive = R.compose(
  R.toLower,
  R.prop("name")
);
const sortByNameDate = R.prop("lastAutomationModifiedDate");

export function MarketPlaceLoadStateHandler(props) {
  const itemsSelectionLimit = 5;
  const initializeSelectApp = item => ({ isSelected: false, name: item.appName, categoryId: item.categoryId, id: item.appId, icon: item.icon });

  const initializeCatogories = item => ({ ...item, isSelected: false });

  const [state, setState] = React.useState({ type: "loading", data: null });
  const [step, setStep] = React.useState("AppSelection");
  const [apps, setApps] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [sortBy, changeSort] = React.useState("Alphabetical");
  const [filteredAutomationList, setAutomationList] = React.useState([]);
  const [search, setSearch] = React.useState({
    initialSearchFocus: false,
    searchText: ""
  });

  const responseData = state.data ? state.data.output : {};

  const automationList = uniqueMarketPlaceAutomations(responseData);
  const selectedItems = apps.filter(app => app.isSelected);
  const headerInfo =
    step === "AutomationListing"
      ? `You have selected ${selectedItems.length} applications`
      : `Maximum ${itemsSelectionLimit}`;
  // console.log("AUTOMATE ::: 01")
  React.useEffect(() => {
    // console.log("AUTOMATE useEffect")
    callApi().then(data => {
      // console.log("AUTOMATE data", data)
      if (!data.response) {
        // console.log("AUTOMATE r1",)
        setState({ type: "data", data: data });
        const applications = R.map(
          initializeSelectApp,
          data.apps
        );
        const sortedApps = R.sortBy(sortByNameCaseInsensitive, applications);

        const urlParams = new URLSearchParams(window.location.search);
        const param = urlParams.get("app");

        // console.log("AUTOMATE r2",)

        if (param) {
          // console.log("AUTOMATE r4",)
          setApps(apps);
          setStep("AutomationListing");
        } else {
          // console.log("AUTOMATE r3",)
          console.log(data.categories, initializeCatogories(data.categories))
          const categoriesModified = R.map(initializeCatogories)(data.categories)
          setCategories([{ name: "All", id: "all", isSelected: true, count: sortedApps.length }, ...categoriesModified])
          setApps(sortedApps);
        }
      } else {
        setState({ type: "error", data: data.response });
      }
    });
  }, []);

  React.useEffect(() => {
    if (step === "AutomationListing") {
      if (selectedItems.length <= 0) {
        setStep("AppSelection");
      } else {
        const allPossibleAppCombinations = getAllSubsets(
          selectedItems.map(app => app.id)
        );

        const filteredAutomations = allPossibleAppCombinations.map(appItem => {
          const filterOnlyMatchingAppSet = R.converge(allAreSame, [
            R.compose(
              x => x.length,
              R.intersection(appItem)
            ),
            R.always(appItem.length),
            R.length
          ]);

          const filterAutomations = R.compose(
            filterOnlyMatchingAppSet,
            R.uniq,
            R.map(R.prop("id")),
            R.prop("apps")
          );

          const matchingAutomationForSingleAppSet = R.filter(filterAutomations)(
            automationList
          );
          return matchingAutomationForSingleAppSet;
        });

        setAutomationList(filteredAutomations);
      }
    }
  }, [step]);

  const selectCategory = (id) => {
    const categoriesModified = categories.map(category => {
      if (category.id === id) {
        return { ...category, isSelected: true }
      }

      return { ...category, isSelected: false }
    })

    setCategories(categoriesModified)
  }
  // console.log("AUTOMATE ::: 02")
  const changeAppSelection = action => item => {
    const typedItem = item;

    const nextState = immer.produce(apps, uniqueApps => {
      uniqueApps.forEach(a => {
        if (a.name == typedItem.name) {
          const toSet = action == "Toggle" ? !typedItem.isSelected : false;
          a.isSelected = toSet;
        }
      });
    });
    setApps(nextState);
  };

  const resetInitialState = () => {
    const appsWithSelectionCleared = immer.produce(apps, apps => {
      apps.forEach(a => {
        a.isSelected = false;
      });
    });
    setApps(appsWithSelectionCleared);

    setSearch({ initialSearchFocus: false, searchText: "" });
  };
  // console.log("AUTOMATE ::: 03")
  const addToWorkSpace = automation => { };

  const selectedCategory = categories.find(category => category.isSelected)

  const filteredApps = apps.filter(app => {

    if (selectedCategory.id == "all")
      return true

    return app.categoryId == selectedCategory.id

  }).filter(app => {
    return (
      app.name
        .toLowerCase()
        .indexOf(search.searchText.toLowerCase()) != -1
    );
  })

  // console.log("AUTOMATE ::: 04")
  const leftMenu = (
    <WrapperLeft>
      <div className="wrapper_left">
        <h4 className="font-section-normal-text-medium">App Category</h4>
        <span className="wrapper_left_mobile">
          <DownArrowCircle />
        </span>
      </div>
      <div className="dropdown_left">
        <div className="dropdown_left_show">
          <ul>
          </ul>
        </div>
      </div>
    </WrapperLeft>
  );
  // console.log("AUTOMATE ::: 05")
  const searchInput = (
    <div className="search_marketpalce search_marketplace_integrations_automate">
      <input
        className="search_click search_click_integrations_automate font-name-smaller-text color-bababa"
        type="search"
        placeholder="Search apps"
        onChange={e => setSearch({ ...search, searchText: e.target.value })}
      />
      <span className="search_icon">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-search fa-w-16 fa-2x"><path fill="currentColor" d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"></path></svg>
      </span>
    </div>
  );
  // console.log("AUTOMATE ::: 1", apps)

  if (apps.length == 0) {
    return (
      <div className="market_place">
        <div className="loader_demo" id="loader_demo">
          <div className="loader"></div>
        </div>
      </div>
    );
  }


  return (
    <>
      <div className="overall_wrapper">
        <div className="market_place">
          <>
            <div className="marketplace_wrapper">
              <div className="container">
                <SelectApplications.Main borderBottom className="fff">
                  {step == "AppSelection" ? (
                    <MarketPlaceItemsAutomate
                      items={filteredApps}
                      selectCategory={selectCategory}
                      categories={categories}
                      leftMenu={leftMenu}
                      selectionLimit={5}
                      searchInput={searchInput}
                      toggleItemSelection={changeAppSelection("Toggle")}
                    ></MarketPlaceItemsAutomate>
                  ) : (
                    <CardsApplications
                      leftMenu={leftMenu}
                      searchInput={React.cloneElement(searchInput, {
                        placeholder: "Search automations"
                      })}
                      listItems={filteredAutomationList.map(automation => {
                        return automation.length > 0
                          ? automation.filter(
                            flow =>
                              flow.name
                                .toLowerCase()
                                .indexOf(search.searchText.toLowerCase()) != -1
                          )
                          : automation;
                      })}
                      addToWorkSpace={addToWorkSpace}
                      redirectToMarketplaceDetail={addToWorkSpace}
                    />
                  )}
                </SelectApplications.Main>
              </div>
            </div>
          </>
        </div>
      </div>
      {/* <Helmet>
        <script src="/marketPlaceScript.js" type="text/javascript" />
      </Helmet> */}
    </>
  );
}

const WrapperLeft = styled.div`
  width: 20%;
  float: left;
  height: 100%;
  display: block;

  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const SelectApplications = {};
SelectApplications.Main = styled.div`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: column;
  border-bottom: ${props => (props.borderBottom ? "" : " 0.5px solid #e2e2e2;")};
`;
const ApplicationsHeader = {};
ApplicationsHeader.Main = styled.h5`
  width: 100%;
  float: left;
  font-size: 16px;
  font-family: "Roboto-Medium";
  color: #494949;
  margin-bottom: 20px;
`;
ApplicationsHeader.Span = styled.span`
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: ${props => (props.emphasizeInfo === true ? "#ff7900" : "#617995")};
`;
export const ApplicationsCard = {};

ApplicationsCard.Main = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  cursor: default;
`;
ApplicationsCard.Left = styled.div`
  width: 83%;
  float: left;
`;
ApplicationsCard.Right = styled.div``;

const CardButtonSpan = styled.span`
  text-align: left;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: left;
  justify-content: left;
`;
const CardButtonSpanRight = styled(CardButtonSpan)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelButton = styled.div`
  width: 15px;
  height: 15px;
  background: #999;
  border: 0.5px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -5px;
  cursor: pointer;
  visibility: hidden;

  :hover {
    background: #0a89e8;
    border: 0.5px solid #fff;
  }
  @media (max-width: 767px) {
    width: 12px;
    height: 12px;
  }
`;
const CardButton = styled("h6")`
  width: 18%;
  height: 36px;
  border-radius: 36px;
  cursor: default;
  float: left;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #494949;
  margin-right: 15px;
  background: #fff;
  border: 0.5px solid #e6e6e6;

  :hover {
    background: #0a89e8;
    color: #fff;
    cursor: pointer;
    border: 0.5px solid #0a89e8;
  }

  &:hover ${CancelButton} {
    visibility: visible;
  }
`;

const RightButton = styled(CardButton)`
  width: 140px;
  font-size: 16px;
  color: #fff;
  background: ${props => (props.disabled == false ? "#0a89e8" : "#0a89e8")};
  opacity: ${props => (props.disabled == false ? 1 : 0.8)};
  cursor: ${props => (props.disabled == false ? "default" : "pointer")};
  border: "0.5px solid #0a89e8";
  border: 0.5px solid #0a89e8;
  margin-right: 0px;
`;
const Avatar = styled.img`
  width: 26px;
  float: left;
  height: 26px;
  margin: 0;
  border-radius: 50%;
  margin: 0px 5px;
`;
const Cancel = styled.span`
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6px;
  height: 6px;

  & > svg {
    fill: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Next = styled.span`
  width: 15px;
  height: 15px;
  & svg {
    fill: #fff;
    transform: rotate(-90deg);
    width: 12px;
    height: 12px;
    display: inline-flex;
    margin-left: 5px;
  }
`;
const WidthAuto = styled.label`
  width: auto;
  float: left;
  margin-bottom: 0px;
`;
