import React from "react";
import { RequestForm } from "../form";
import useMedia from "use-media";

RegisterSection.LogoImage = ({ children, additionalClassLogoImage }) => {
  return (
    <img
      className={`bamboo_logo_image ${
        additionalClassLogoImage ? additionalClassLogoImage : ""
      }`}
      src={children}
    ></img>
  );
};

RegisterSection.MainHeader = ({ children, additionalClassHeader }) => {
  return (
    <h1
      className={`${"font-page-header"} ${
        additionalClassHeader ? additionalClassHeader : ""
      }`}
    >
      {children}
    </h1>
  );
};

RegisterSection.SubHeader = ({ children, additionalClassSubHeader }) => {
  return (
    <p
      className={`${"font-section-normal-text mb-0 pb-0"} ${
        additionalClassSubHeader ? additionalClassSubHeader : ""
      }`}
    >
      {children}
    </p>
  );
};

RegisterSection.FormWithContent = ({ children, product, content, noform, fromPage }) => {
  return (
    <div className="main-slider-left">
      {children}
      {!noform ? (
        product == "automate" ? (
          <RequestForm
            product="automate"
            AutomateCTA
            automateContent={true}
            content={content}
            isFooterForm={false}
            fromPage={fromPage}
          />
        ) : (
          <RequestForm isFooterForm={false}  fromPage={fromPage} />
        )
      ) : null}
    </div>
  );
};

export default function RegisterSection({
  children,
  rightImage,
  rightImageMobile,
  backgroundColor,
  additionalClass,
  altImage,
  iD,
  Image,
  homePageSignUp,
  bannerImage,
  bannerImageMobile,
  additionalClassContainer,
  additionalClassBanner,
  additionalClassImage,
  customerText,
  leftContainer,
  rightContainer,
}) {
  const isMobile = Image;
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section
      className={`${"assistant_slider beta-main-slider trial_mb_80 bg_header_trial"} ${
        backgroundColor ? backgroundColor : "bg_slider_home"
      } ${additionalClass ? additionalClass : ""}`}
      id={iD ? iD : ""}
    >
      <div className="container">
        <div className={homePageSignUp ? `row main-slider-left` : `row`}>
          <div
            className={`col-lg-6 col-md-5 col-sm-12 col-xs-12 trial_header_left ibm-left-section-bottom ${
              leftContainer ? leftContainer : ""
            }`}
          >
            {children}
          </div>
          <div
            className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 cont_right ${
              rightContainer ? rightContainer : ""
            }`}
          >
            <div
              className={
                bannerImage
                  ? "main-slider-right beta-slider"
                  : "main-slider-right assistant_right_img beta-slider"
              }
            >
              {/* <div className="main-slider-right assistant_right_img beta-slider"> */}

              {Image ? (
                <picture>
                  <source media="(max-width:768px)" srcSet={rightImageMobile} />
                  <img src={rightImage} altImage={altImage}></img>
                </picture>
              ) : (
                <img
                  className={` ${""} ${
                    additionalClassImage ? additionalClassImage : ""
                  }`}
                  src={rightImage}
                  alt={altImage ? altImage : "header_img"}
                />
              )}
            </div>
          </div>
        </div>
        {bannerImage ? (
          <div
            className={`${"container"} ${
              additionalClassContainer ? additionalClassContainer : ""
            } ${additionalClassBanner ? additionalClassBanner : ""}`}
          >
            <h5 className="text-center margin-bottom-20 font-section-sub-header-slider">
              {customerText
                ? customerText
                : "Join hundreds of industry leaders"}
            </h5>{" "}
            {isSmall ? (
              <img
                className=""
                src={bannerImageMobile}
                alt={altImage ? altImage : "header_img"}
              />
            ) : (
              <img
                className={` ${""} ${additionalClass ? additionalClass : ""}`}
                src={bannerImage}
                alt={altImage ? altImage : "header_img"}
              />
            )}
          </div>
        ) : null}
      </div>
    </section>
  );
}
// export default function RegisterSection() {
// 	return (
// 		<section className=" assistant_slider beta-main-slider trial_mb_80 bg_header_trial">
// 			<div className="container">
// 				<div className="row">
// 					<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 trial_header_left">
// 						<div className="main-slider-left">
// 							<h1>
// 								Deflect your support calls. Give your employees a personalized chatbot for IT &amp; HR
// 								support.{' '}
// 							</h1>
// 							<p>
// 								Save IT Helpdesk costs with Workativ Assistant’s AI-powered Automations. No coding, we
// 								promise!{' '}
// 							</p>
// 							<div className="main-slider-button beta-button">
// 								<form name="form">
// 									<input
// 										type="text"
// 										id="beta-name"
// 										onkeyup="handlechange(event)"
// 										name="firstname"
// 										placeholder="Name"
// 										required
// 									/>
// 									<input
// 										type="text"
// 										id="beta-email"
// 										onkeyup="handlechange(event)"
// 										name="lastname"
// 										placeholder="Email"
// 										required
// 									/>
// 									{/* Button trigger modal */}
// 									<button
// 										type="button"
// 										onclick="createContect(event)"
// 										id="register-for-trail"
// 										className="btn btn-primary disabled_button"
// 									>
// 										Register for Trial
// 									</button>
// 								</form>
// 							</div>
// 						</div>
// 					</div>
// 					<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
// 						<div className="main-slider-right assistant_right_img beta-slider">
// 							<img className src={Image} alt="trial_header_img" />
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</section>
// 	);
// }
