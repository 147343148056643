import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";

const OgImage = require("../../assets/automate/home/automate_home_header.png");

export default function DemoPage() {
  return (
    <>
      <TitleAndMetaTags
        title="Workflow Automation Demo | Workativ Automate"
        description="Free workflow automation demo. Learn how workativ can transform your workplace  using no-code workflow automation software in minutes."
        keywords={["workativ"]}
        ogTitle="Workflow Automation Demo | Workativ Automate"
        ogDescription="Free workflow automation demo. Learn how workativ can transform your workplace  using no-code workflow automation software in minutes."
        ogImage={OgImage}
      />
      <Container>
        <div className="automate_demo automate_product demo_automate_new resolution_automate_first_automate_demo">
          <Layout
            backgroundColor={"roi_bg bg_header_automation"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
          >
            <DemoPageContentAndForm formName={"DemoForm"}>
              <div className="demo-left-automate-content">
                <DemoPageContentAndForm.Header>
                  Automate workflows in minutes
                </DemoPageContentAndForm.Header>
                <DemoPageContentAndForm.Content>
                  Leverage our SaaS platform to automate workflows with zero
                  coding.
                </DemoPageContentAndForm.Content>
                <DemoPageContentAndForm.H6>
                  Our Live demo covers:
                </DemoPageContentAndForm.H6>
                <DemoPageContentAndForm.Ul>
                  {liContent.map((li) => (
                    <DemoPageContentAndForm.Li>
                      {li.content}
                    </DemoPageContentAndForm.Li>
                  ))}
                </DemoPageContentAndForm.Ul>
              </div>
            </DemoPageContentAndForm>
            <WhyAutomate />
            <CustomerMobile />
            <div className="automate_homepage">
              <RequestForm
                product="automate"
                content="Start on our free plan, and scale up as you grow."
                AutomateCTA
                automateContent
                autoamteButton
                automateName
                isFooterForm={true}
              />
            </div>
          </Layout>
        </div>
      </Container>
    </>
  );
}

const liContent = [
  {
    content: "How workativ automate no-code platform works",
  },
  {
    content: "Connecting apps",
  },
  {
    content: "Automate app workflows with triggers",
  },
  {
    content: "Use pre-built workflow from marketplace",
  },
  {
    content: "Analytics",
  },
];

DemoPageContentAndForm.Header = ({ children }) => {
  return <h1 className="font-page-header">{children}</h1>;
};
DemoPageContentAndForm.Content = ({ children }) => {
  return <p className="font-section-normal-text">{children}</p>;
};
DemoPageContentAndForm.H6 = ({ children }) => {
  return (
    <h6 className="font-section-normal-text-medium our-live-demo-margin-mobile">
      {children}
    </h6>
  );
};
DemoPageContentAndForm.Ul = ({ children }) => {
  return (
    <ul className="font-section-normal-text --no-list-style pl-0">
      {children}
    </ul>
  );
};
DemoPageContentAndForm.Li = ({ children }) => {
  return (
    <li className="font-section-normal-text demo-page-before-tick">
      {children}
    </li>
  );
};

DemoPageContentAndForm.Form = ({ children }) => {
  return <>{children}</>;
};

export function DemoPageContentAndForm({ children, formName }) {
  return (
    <section className="skit_contactus sign_up-form_us demo_page roi_bg">
      <div className="container signup_form">
        <div className="row beta_change_form">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mobile-padding-demo">
            <div className="roi-form roi-form-demo">
              <h1 className="font-section-normal-text">Book a Demo</h1>
            </div>
            {children}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 demo_page_right mobile-padding-demo">
            {formName === "DemoForm" ? <DemoForm /> : null}
          </div>
        </div>
      </div>
    </section>
  );
}

function DemoForm() {
  return (
    <>
      <div className="icon_arrow">
        <div className="arrow_demo" />
      </div>
      <div className="demo_page_form">
        <h4>Schedule a FREE Demo</h4>
        {/* Calendly inline widget begin */}
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/workativ/demo_discovery_call?hide_event_type_details=1"
          style={{ position: "relative", width: "100%", height: 440 }}
          data-processed="true"
        >
          <div className="calendly-spinner">
            <div className="calendly-bounce1" />
            <div className="calendly-bounce2" />
            <div className="calendly-bounce3" />
          </div>
          <iframe
            src="https://calendly.com/workativ/demo_discovery_call?embed_domain=monitor.cognidesk.ai%3A2019&embed_type=Inline&hide_event_type_details=1"
            width="100%"
            height="100%"
            frameBorder={0}
          />
        </div>
        {/* Calendly inline widget end */}
      </div>
    </>
  );
}

function WhyAutomate() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="why_autoamte_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-12 why-automate-right-main-flex">
            <h3 className="font-section-sub-header">Why Workativ Automate?</h3>
            <p className="font-section-normal-text-testimonials line-height-18">
              Most workflow products are complex, expensive, and bloated with
              things that you don’t need. Workativ Automate is purpose built
              iPaaS to help you connect and automate your workplace tasks. It’s
              easy to use yet powerful, and affordable to all.
            </p>
            {isSmall ? null : (
              <div className="why-automate-customer-banner">
                <h5 className="font-section-sub-header-slider banner-header-why-automate">
                  Join leading innovators
                </h5>
                <img
                  src={require("../../assets/automate/home/automate_clients_logo_mobile.svg")}
                  loading="lazy"
                  alt="join leading innovators"
                />
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-6 col-12">
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_1.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  Simple and Intuitive
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Create app integrations and workflows using simple UI
                  interface. No coding or developer support required.
                </p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_2.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  Powerful Tools
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Format data, add conditional logic, set approvals, or time
                  intervals into your workflows.
                </p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_3.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  100+ Connectors
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Connect 100+ cloud apps. Use app triggers, webhooks to run
                  your workflows.
                </p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img
                  src={require("../../assets/automate/home/features_icon_4.png")}
                />
              </div>
              <div className="three_steps_right">
                <h3 className="font-section-sub-header-small">
                  Secure & Reliable
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18 pb-0">
                  Data encryption at rest and transit, data retention controls
                  and audit logs. ISO 27001 certified.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export function CustomerMobile() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="float-left width-100 why-customer-banner-mobile-section">
      {isSmall ? (
        <div className="container">
          <div className="why-automate-customer-banner">
            <h5 className="font-section-sub-header-small banner-header-why-automate">
              Join leading innovators
            </h5>
            <img
              src={require("../../assets/automate/home/automate_clients_logo_mobile.svg")}
            />
          </div>
        </div>
      ) : null}
    </section>
  );
}
